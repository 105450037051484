import { Link } from '@/routes-utils/navigation.tsx';
import { Button } from '@synoptic/ui-kit/button/button.tsx';
import { Checkbox } from '@synoptic/ui-kit/checkbox/checkbox.tsx';
import { Input } from '@synoptic/ui-kit/input/input.tsx';
import { useToast } from '@synoptic/ui-kit/toast/toast-provider.tsx';
import { signUp } from 'aws-amplify/auth';
import mixpanel from 'mixpanel-browser';
import { FC, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { paths } from '@/routes-utils/paths';
import { pushService } from '@/services/push-service.ts';
import { traceError } from '@/utils/trace-error.ts';
import { PasswordInput } from '@synoptic/ui-kit/input/password-input.tsx';
import { passwordPattern } from '../../form-utils/password-pattern';
import {
  actionLink,
  checkbox,
  formFieldsContainer,
  submitAuthFormButton,
} from '../auth/page.css';
import { Form, PrefetchPageLinks } from 'react-router';

type FormInputs = {
  name: string;
  password: string;
  invitationCode: string;
  policy: boolean;
};

export const LandingSignupForm: FC<{
  email: string;
  onSignup: (username: string, email: string) => void;
  inviteCodeQueryParam: string | null;
}> = ({ email, onSignup, inviteCodeQueryParam }) => {
  const { handleSubmit, formState, reset, register, watch, setValue } =
    useForm<FormInputs>({
      defaultValues: {
        invitationCode: inviteCodeQueryParam ?? undefined,
      },
    });
  const formRef = useRef<HTMLFormElement>(null);
  const toast = useToast();
  const policy = watch('policy', false);

  const submit: SubmitHandler<FormInputs> = async (data) => {
    const { name, password, invitationCode } = data;
    const username = uuidv4();

    try {
      const subscription = await pushService.getSubscription();
      if (subscription) {
        await subscription.unsubscribe();
      }
    } catch (err) {
      traceError(
        new Error('Failed to subscribe on push notifications on signup', {
          cause: err,
        }),
      );
    }

    try {
      await signUp({
        username,
        password,
        options: {
          userAttributes: { email, name, 'custom:invite_code': invitationCode },
          autoSignIn: true,
        },
      });
      mixpanel.track('Sign Up', {
        username,
        email,
        'custom:invite_code': invitationCode,
      });

      onSignup(username, email);
      reset();
    } catch (e) {
      toast.error({ title: String(e) });
    }
  };

  return (
    <>
      <Form ref={formRef} onSubmit={handleSubmit(submit)} action={paths.signup}>
        <div className={formFieldsContainer}>
          <Input
            {...register('name', {
              required: true,
              maxLength: 50,
            })}
            placeholder={
              'Enter your profile name (will be visible to other users)'
            }
            label={'Your name'}
            autoComplete="name"
          />

          <PasswordInput
            {...register('password', {
              required: true,
              pattern: passwordPattern,
            })}
            placeholder={'Enter your password'}
            errorMessage={formState.errors.password?.message}
            label={'Password'}
            autoComplete="new-password"
          />

          <Input
            {...register('invitationCode', { required: false })}
            placeholder={'Enter your invitation code'}
            autoComplete={'off'}
            label={'Invitation code'}
            markOptional
          />

          <label className={checkbox}>
            <Checkbox
              {...register('policy')}
              onCheckedChange={(checked: boolean) =>
                setValue('policy', checked)
              }
            />
            <span>
              I agree to the{' '}
              <Link
                className={actionLink}
                to={paths.termsOfUse}
                target={'_blank'}
              >
                Terms and Conditions
              </Link>{' '}
              and{' '}
              <Link
                className={actionLink}
                to={paths.privacyPolicy}
                target={'_blank'}
              >
                Privacy Policy
              </Link>
              .
            </span>
          </label>

          <Button
            className={submitAuthFormButton}
            size={'large'}
            type="submit"
            disabled={!policy}
            loading={formState.isSubmitting}
          >
            Sign up
          </Button>
        </div>
      </Form>
      <PrefetchPageLinks page={paths.signup} />
    </>
  );
};
